/* App.css */
body {
  margin: 0px;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif !important;
}
.rulesPage,
.AdminPanel {
  background: rgb(70, 115, 157);
  background: linear-gradient(
    90deg,
    rgba(70, 115, 157, 1) 0%,
    rgba(80, 75, 129, 1) 100%,
    rgba(80, 75, 129, 1) 100%
  );
  color: #fff;
  padding: 20px;
  min-height: 100vh;
  height: fit-content;
  margin: auto;
  font-family: Arial, sans-serif;
  box-sizing: border-box;
}

.AdminPanel h2 {
  margin-bottom: 20px;
  font-size: 24px;
}

.AdminPanel input[type="file"] {
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 5px;
  border: 2px solid #fff;
  background-color: transparent;
  color: #fff;
}

.AdminPanel .adminPanelButton {
  background-color: #fff;
  color: rgba(70, 115, 157, 1);
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px;
  transition: background-color 0.3s ease;
}

.AdminPanel button:hover {
  background-color: #fff5fd;
}

.AdminPanel a {
  display: block;
  color: #fff;
  text-decoration: none;
  margin-bottom: 10px;
  font-size: 18px;
}

.AdminPanel a:hover {
  text-decoration: underline;
}

.AdminPanel .file-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.AdminPanel .file-item span {
  font-size: 16px;
  margin-right: 10px;
}

.AdminPanel .file-item button {
  background-color: #fff;
  color: rgba(70, 115, 157, 1);
  border: none;
  padding: 8px 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.AdminPanel .file-item button:hover {
  background-color: #fff5fd;
}
.appRoutes {
  background: rgb(70, 115, 157);
  background: linear-gradient(
    90deg,
    rgba(70, 115, 157, 1) 0%,
    rgba(80, 75, 129, 1) 100%,
    rgba(80, 75, 129, 1) 100%
  );
}
.App {
  background: rgb(70, 115, 157);
  background: linear-gradient(
    90deg,
    rgba(70, 115, 157, 1) 0%,
    rgba(80, 75, 129, 1) 100%,
    rgba(80, 75, 129, 1) 100%
  );
  color: #fff;
  padding: 20px;

  min-height: 100vh;
  height: fit-content;
  margin: auto;
  font-family: Arial, sans-serif;
  box-sizing: border-box;
}

input,
.App button {
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: white;
  color: rgba(70, 115, 157, 1);
  box-sizing: border-box;
}

button {
  transition: background-color 0.3s ease;
  box-sizing: border-box;
}

.App a,
.navbar a,
.navbar-menu div a {
  box-sizing: border-box;
  display: block;
  margin-top: 20px;
  background-color: white;
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  color: rgba(70, 115, 157, 1);
  text-decoration: none;
}

.countdown {
  margin-bottom: 40px;
}

.countdown input[type="datetime-local"] {
  box-sizing: border-box;
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: none;
  border-radius: 5px;
}

.countdown div {
  font-size: 18px;
  font-weight: bold;
}
.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: center;
  padding: 10px 15px;
  background-color: white;
  justify-content: space-between;
  flex-direction: row;
  z-index: 9999999;
  box-sizing: border-box;
}
.navbar-logo a {
  padding: 0;
  margin: 0;
}
.navbar-logo img {
  width: 70px;
}
.navbar-menu {
  font-size: 2em;
  display: none;
  position: fixed;
  top: 0;
  width: 100vw;
  height: 0;
  background-color: white;
  padding-top: 80px;
  padding-left: 20px;
  box-sizing: border-box;
}
.menu-icon img {
  width: 50px;
}
.menu-icon-x img {
  width: 25px;
}
.navbar-menu.open {
  display: block;
  height: 100vh;
}
.App {
  padding-top: 60px;
}

.AdminPanel {
  padding-top: 80px;
}

.countdownTimer {
  box-sizing: border-box;
  width: 100%;
  color: white;
  margin-top: 50px;
  text-align: center;
  font-size: 2.5em !important;
}
.answerRow {
  margin-bottom: 30px;
}
.sponsorImg {
  width: 100%;
}
.sponsors-container {
  width: 100%;
  background-color: white;
  border-radius: 25px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5%;
  padding-bottom: 5%;
  padding-top: 5%;
  padding-left: 5%;
  padding-right: 5%;
  box-sizing: border-box;
  margin-top: 50px;
}
.sponsor {
  box-sizing: border-box;
  width: 47.5%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}
.sponsorImg {
  box-sizing: border-box;
  width: 100%;
}
.sponsorBig {
  margin-left: 5%;
  box-sizing: border-box;
  width: 90%;
}
@media (min-width: 768px) {
  .sponsor {
    width: 21%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  }

  .sponsorBig {
    margin-left: 2.5%;
    width: 45%;
  }
}
.rulesPage {
  padding-top: 120px;
}
.ruleTitle {
  box-sizing: border-box;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background-color: rgb(76, 76, 167);
  cursor: pointer;
}

.ruleTitle:hover {
  background-color: rgb(46, 46, 100);
}

.toggleButton {
  width: fit-content;
  border: none;
  background-color: transparent;
  font-size: 20px;
  cursor: pointer;
}

.ruleDesc {
  padding: 10px;
  background-color: rgb(76, 76, 167);
  border: 1px solid rgb(46, 46, 100);
}
.navbarDiv {
  font-weight: 500;
  gap: 10px;
  display: flex !important;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.x {
  font-size: 14px;
}
.aubLogo {
  font-weight: 600;
  font-size: 1.3em !important;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
.login {
  color: white;
  flex-direction: column;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.marginTop {
  margin-top: 20px;
}
.stateButtons {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
}
.App .stateButtons {
  margin-top: 60px;
}

.stateButton {
  background-color: #fff;
  color: rgba(70, 115, 157, 1) !important;
  border: 1px solid rgb(46, 46, 100) !important;
  padding: 10px 20px;
  margin: 0px !important;

  border-radius: 0px !important;
  /* Your button styles */
}
.selectedButton {
  background-color: rgb(46, 46, 100) !important;
  color: white !important;
}
/* For mobile devices, 2 buttons per row */
@media screen and (max-width: 768px) {
  .stateButton {
    flex-basis: 50%;
  }
}

/* For larger screens, 4 buttons per row */
@media screen and (min-width: 769px) {
  .stateButton {
    flex-basis: 25%;
  }
}
